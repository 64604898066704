import React, { useState, useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import BlogPost from '../components/BlogPost';
import Categories from '../components/Categories';
import BlogSearch from '../components/BlogSearch';
import TagCloud from '../components/TagCloud';
import NewsletterSignup from '../components/NewsletterSignup';
import PostSorting from '../components/PostSorting';
import { blogService } from '../services/blogService';
import '../styles/Blog.css';

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1');
  const currentCategory = searchParams.get('category') || '';
  const currentTag = searchParams.get('tag') || '';
  const searchQuery = searchParams.get('search') || '';
  const sortBy = searchParams.get('sort') || 'newest';
  const postsPerPage = 8;

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      try {
        const data = await blogService.getPosts({
          page: currentPage,
          limit: postsPerPage,
          category: currentCategory,
          tag: currentTag,
          search: searchQuery,
          sort: sortBy
        });
        console.log('Fetched posts:', data);
        setPosts(data.posts);
        setTotalPages(Math.ceil(data.total / postsPerPage));
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
      setLoading(false);
    };

    fetchPosts();
  }, [currentPage, currentCategory, currentTag, searchQuery, sortBy]);

  const handlePageChange = (newPage) => {
    const params = new URLSearchParams(searchParams);
    params.set('page', newPage.toString());
    setSearchParams(params);
    window.scrollTo(0, 0);
  };

  const clearFilters = () => {
    setSearchParams({ page: '1' });
  };

  const formatBlogTitle = (slug) => {
    // URL'deki başlığı düzgün formatlama
    const words = slug.split('-');
    
    const specialWords = {
      'rgm': 'RGM',
      'tpm': 'TPM',
      'ai': 'AI',
      've': 'Ve',
      'perakende': 'Perakende',
      'aktivitelerinde': 'Aktivitelerinde',
      'ocrion': 'Ocrion',
      'anlik': 'Anlık',
      'veri': 'Veri',
      'aninda': 'Anında',
      'aksiyon': 'Aksiyon',
      'revenue': 'Revenue',
      'growth': 'Growth',
      'management': 'Management'
    };

    return words.map(word => 
      specialWords[word.toLowerCase()] || 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  };

  if (loading) {
    return (
      <div className="blog-page">
        <div className="loading">Loading...</div>
      </div>
    );
  }

  return (
    <div className="blog-page">
      <div className="blog-container">
        <main className="blog-main">
          <h1>Blog</h1>
          <div className="blog-filters">
            <BlogSearch />
            <PostSorting />
            {(currentCategory || currentTag || searchQuery) && (
              <button onClick={clearFilters} className="clear-filters">
                Clear Filters
              </button>
            )}
          </div>
          
          {(currentCategory || currentTag || searchQuery) && (
            <div className="active-filters">
              {currentCategory && <span className="filter-tag">Category: {currentCategory}</span>}
              {currentTag && <span className="filter-tag">Tag: {currentTag}</span>}
              {searchQuery && <span className="filter-tag">Search: {searchQuery}</span>}
            </div>
          )}

          <div className="blog-posts">
            {posts.length > 0 ? (
              posts.map(post => (
                <div key={post.id} className="blog-post">
                  <Link to={`/blog/${post.slug}`}>
                    <BlogPost post={post} />
                  </Link>
                </div>
              ))
            ) : (
              <div className="no-posts">
                <p>No posts found.</p>
              </div>
            )}
          </div>
          
          <div className="pagination">
            <button 
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="pagination-button"
            >
              Previous
            </button>
            
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
              >
                {index + 1}
              </button>
            ))}
            
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="pagination-button"
            >
              Next
            </button>
          </div>
        </main>
        
        <aside className="blog-sidebar">
          <BlogSearch />
          <Categories />
          <TagCloud />
          {/* Newsletter bölümünü şimdilik gizle 
          <NewsletterSignup />
          */}
        </aside>
      </div>
    </div>
  );
};

export default Blog; 