import React from 'react';
import '../styles/Contact.css';
import handshakeImg from '../assets/slides/contact/handshake.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faGlobe, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {
  return (
    <div className="contact-page">
      <div className="contact-hero">
        <img src={handshakeImg} alt="Ocrion İletişim" className="contact-image" />
        <div className="contact-overlay">
          <div className="hero-content">
            <img 
              src="/logos/ocrion-long.png" 
              alt="Ocrion Logo" 
              className="hero-logo"
            />
            <h1>Bize Ulaşın</h1>
          </div>
        </div>
      </div>

      <div className="contact-info">
        <div className="contact-card">
          <FontAwesomeIcon icon={faHome} className="contact-icon" />
          <h3>Adres</h3>
          <p>Kozyatağı Mah. Şehit İlknur Keleş Sok. Anaç-Aksakal Plaza, No:10/8, 34742,</p>
          <p>Kadıköy, İstanbul / Türkiye</p>
        </div>

        <div className="contact-card">
          <FontAwesomeIcon icon={faGlobe} className="contact-icon" />
          <h3>Web Sayfası</h3>
          <a href="https://www.ocrion.com" target="_blank" rel="noopener noreferrer">
            www.ocrion.com
          </a>
        </div>

        <div className="contact-card">
          <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
          <h3>E-Mail</h3>
          <a href="mailto:info@ocrion.com">info@ocrion.com</a>
        </div>
      </div>
    </div>
  );
};

export default Contact; 