import React from 'react';
import { useSearchParams } from 'react-router-dom';

const PostSorting = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentSort = searchParams.get('sort') || 'newest';

  const sortOptions = [
    { value: 'newest', label: 'Newest First' },
    { value: 'oldest', label: 'Oldest First' },
    { value: 'popular', label: 'Most Popular' },
    { value: 'comments', label: 'Most Commented' }
  ];

  const handleSortChange = (value) => {
    const params = new URLSearchParams(searchParams);
    params.set('sort', value);
    params.set('page', '1'); // Reset to first page on sort change
    setSearchParams(params);
  };

  return (
    <div className="post-sorting">
      <span className="sort-label">Sort by:</span>
      <div className="sort-options">
        {sortOptions.map(option => (
          <button
            key={option.value}
            onClick={() => handleSortChange(option.value)}
            className={`sort-option ${currentSort === option.value ? 'active' : ''}`}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default PostSorting; 