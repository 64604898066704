import { useState } from 'react';
import { Link } from 'react-router-dom';

function Admin() {
  const [activeTab, setActiveTab] = useState('posts');

  return (
    <div className="admin-page">
      <header className="page-header">
        <h1>Admin Dashboard</h1>
        <div className="admin-tabs">
          <button 
            className={`tab-btn ${activeTab === 'posts' ? 'active' : ''}`}
            onClick={() => setActiveTab('posts')}
          >
            Posts
          </button>
          <button 
            className={`tab-btn ${activeTab === 'categories' ? 'active' : ''}`}
            onClick={() => setActiveTab('categories')}
          >
            Categories
          </button>
        </div>
      </header>
      <div className="admin-content">
        {activeTab === 'posts' && (
          <div className="posts-section">
            <div className="section-header">
              <h2>Blog Posts</h2>
              <Link to="/admin/new-post" className="new-btn">New Post</Link>
            </div>
            <div className="posts-list">
              <p>No posts yet</p>
            </div>
          </div>
        )}
        {activeTab === 'categories' && (
          <div className="categories-section">
            <div className="section-header">
              <h2>Categories</h2>
              <button className="new-btn">Add Category</button>
            </div>
            <div className="categories-list">
              <p>No categories yet</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Admin; 