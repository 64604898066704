import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { blogService } from '../services/blogService';

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [searchParams] = useSearchParams();
  const currentCategory = searchParams.get('category') || '';

  useEffect(() => {
    const fetchCategories = () => {
      const cats = blogService.getCategories();
      setCategories(cats);
    };
    fetchCategories();
  }, []);

  return (
    <div className="categories">
      <h3>Categories</h3>
      <ul className="category-list">
        {categories.map((category, index) => (
          <li key={index}>
            <Link 
              to={`/blog?category=${category}`}
              className={currentCategory === category ? 'active' : ''}
            >
              {category}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Categories; 