import React from 'react';
import '../styles/CookiePolicy.css';

const CookiePolicy = () => {
  return (
    <div className="cookie-policy">
      <div className="container">
        <h1>Çerez Politikası</h1>
        
        <section>
          <h2>Çerez Nedir?</h2>
          <p>
            Çerezler web sitesi ziyareti sırasında ilgili sunucu tarafından bilgisayar, tablet ve/veya akıllı telefonlara gönderilen bir dizi karakter içeren basit, genellikle harf ve rakamlardan oluşan küçük bir metin dosyası olup, çerezin içeriğine erişip bilgileri okuyabilmektedir. Her çerez, web tarayıcısına özeldir. Ziyaretçinin minimum hafıza alanı kullanarak ve bilgisayar, tablet ve/veya akıllı telefonlara tehlikeye sokmadan, mevcut oturum sırasında sabit diske çerezleri kaydedilmesini sağlar. Çerezler IP adresiniz, oturum bilgileriniz, eriştiğiniz sayfalar ve benzer verileri saklamaktadır. Çerezler sayesinde web sitesi tercihleri hatırlandığı gibi, oturumun açık kalması sağlanabilir veya ilgilendiğiniz içerikler sunulmaktadır. Tarayıcıların büyük kısmı standart olarak çerezleri kabul eder ve bunlardan bağımsız olarak, geçici veya depolanmış çerezlerin güvenlik ayarlarına izin verir veya engellemektedir.
          </p>
        </section>

        <section>
          <h2>Çerez Kategorileri</h2>
          
          <h3>Kesinlikle Gerekli / Zorunlu Çerezler</h3>
          <p>
            Web Sitesinin doğru şekilde çalışmasını sağlayan ve site özelliklerini kullanmanıza imkan tanıyan teknik bir çerezdir. Ziyaretçilerin bir sayfanın güvenli alanlarına erişim gibi sitenin özel alanlarında dolaşmasına ve kullanabilmesi için gereklidir. Kesinlikle gerekli çerezler olmadan web sitesinin bazı özelliklerinin kullanılamaması sonucu doğabilir. Operasyonel olarak hizmet, uygulama, araçlar için gereklidir. Kesin gerekli / zorunlu çerezler için ziyaretçi onayı gerekmemektedir.
          </p>

          <h3>Performans Çerezleri</h3>
          <p>
            Web sitesini tekrar ziyaret eden ziyaretçilerin, dil/bölge vb. seçimlerinin hatırlanmasını sağlayarak daha kişiselleştirilmiş deneyim sunmayı sağlamaktadır.
          </p>

          <h3>İşlevsellik Çerezleri</h3>
          <p>
            Web sitesini ziyaret eden ziyaretçi sayısını ve siteyi nasıl kullandıkları hakkında bilgi toplayan çerezlerdir. Web sitesi raporu derlemek amacıyla kullanılır. Web sitesini, ziyaretçilerin nasıl kullandığını anlamak, sitenin performansını ölçümlemek, siteyi optimize edip, geliştirmek, ilgi çekici ve amacına uygunluğundan emin olmak amacıyla kullanılmaktadır. Performans çerezlerinin çıktıları; görüntülenen sayfaları yönlendirilen sayfalar, çıkış yapılan sayfayı, ziyaretlerin zaman dilimi, fare (mouse) hareketleri, ekran sayfa kaydırma etkinliği, ziyaretçilerin web sayfalarında aldıkları hata mesajlarını alıp almadıkları, site içinde aranan arama terimleri göstermektedir. Bu çerezler, bir ziyaretçiyi tanımlamaz, anonim olarak bilgi toplama işlemi gerçekleştirmektedir.
          </p>

          <h3>Reklam, Pazarlama ve Hedeflemeye Dair Çerezler</h3>
          <p>
            Web sitesi ziyaretçilerinin ilgi alanlarına uygun reklam kampanyası etkinliği yaratmak ve reklam sayısını ölçümlemek amacıyla birinci taraf çerezleri, üçüncü taraf çerezler ve web işaretçileri kullanılmaktadır. Bu çerezler belirli web sayfa ziyaretlerinde etkileşim kurduğu reklam kampanyası etkinliğini ölçümlemek ve potansiyel reklam verenlerle iletişime imkan tanımak içinde kullanılmaktadır. Herhangi bir reklama olan ilgi dışında sunulan reklam ve içeriğinde faydalılığını anlamak için teknolojinin kullanılmasını da içermektedir. Bu çerezlerin tarayıcı ayarları değiştirilerek engellenmesi mümkündür.
          </p>
        </section>

        <section>
          <h2>Web İşaretçileri (Web Beacons)</h2>
          <p>
            Ziyaretçilerin davranışlarını tespit etmek amacıyla çerezler ile birlikte çalışan ufak kısa grafik imgeleridir. Aynı zamanda "pixel etiketleri" ve/veya "clear GIFler" olarakta bilinmektedir.
          </p>
        </section>

        <section>
          <h2>Çerez Türleri</h2>
          
          <h3>Oturum Çerezleri</h3>
          <p>
            Oturum çerezleri, tarayıcı oturumu sonlandığında sona eren geçici çerezler olup, oturumun sonlandırılmasıyla silinir. Web sitesinin düzgün çalışmasını sağlamak amacında çerezlerdir.
          </p>

          <h3>Kalıcı Çerezler</h3>
          <p>
            Kalıcı çerezler, ziyaretçinin tercih ettiği ve bir çok site üzerindeki işlemleri hatırlatma imkanı tanıyan, tarayıcı oturumları sonra veya bilgisayar, tablet veya akıllı cihazınızı açıp kapattıktan sonra dahi, cihazda saklanan çerezlerdir. Tarayıcı kapattıktan sonra da ziyaretçi tarafından silinmedikçe veya süresi dolmadıkça cihazda kalan çerezlerdir.
          </p>

          <h3>Birinci Taraf Çerezler</h3>
          <p>
            Birinci taraf çerezler; ziyaret edilen web sitesi tarafından yerleştirilen çerezlerdir.
          </p>

          <h3>Üçüncü Taraf Çerezler</h3>
          <p>
            Üçüncü taraf çerezler; ziyaret edilen web sitesi dışındaki kişiler tarafından yerleştirilen ve kontrol edilen çerezlerdir. Ocrion, size daha iyi, güvenli ve hızlı deneyim sunmak amacı ile reklam ve içerik sunulmasını sağlayan, site ölçüm ve analizlerini yapan teknolojileri kullanan hizmet sağlayıcı üçüncü taraflar ile çalışmaktadır. Hizmet sağlayıcıların; Ocrion web sitesini ziyaret eden kişilere ait kişisel verileri kendi amaçları için toplamalarına Ocrion tarafından izin verilmemekte olup, karşılıklı imzalanan sözleşmeler Kişisel Verileri Koruma Kanununa bağlı yasal kısıtlamalar çerçevesindedir.
          </p>
        </section>

        <section>
          <h2>Ocrion Tarafından Kullanılan Üçüncü Taraf Çerezler</h2>
          <p>
            Ocrion, üçüncü taraf sitelerindeki davranışlarınız hakkında profil oluşturmak için çerez kullanmamakla birlikte, sizin ilgili olabileceğiniz ürün veya hizmetlere uygun reklam vermek ve tanıtım yapmak amacıyla üçüncü taraflardan alınmış verileri kullanmaktadır. Ocrion bünyesinde çalışan kişilerden bu verileri görmeye yetkili olanlar, iş ortaklarımız da çalışan ve verileri görmeye yetkili olanlar ve iki tarafa da dahil olmayıp tek seferlik alınan hukuki, denetsel ve sistemsel desteklerde çalışıp veriyi görmeye yetkisi olmayan kişiler hariç üçüncü kişiler ile paylaşılmamaktadır.
          </p>

          <ul>
            <li>Hotjar: <a href="https://www.hotjar.com/legal/policies/privacy/" target="_blank" rel="noopener noreferrer">Gizlilik Politikası</a></li>
            <li>Google Analytics: <a href="https://policies.google.com/privacy?hl=en-US" target="_blank" rel="noopener noreferrer">Gizlilik Politikası</a></li>
            <li>Google Tag Manager: <a href="https://marketingplatform.google.com/about/analytics/tag-manager/use-policy/" target="_blank" rel="noopener noreferrer">Kullanım Politikası</a></li>
            <li>reCAPTCHA: <a href="https://policies.google.com/privacy?hl=en-US" target="_blank" rel="noopener noreferrer">Gizlilik Politikası</a></li>
          </ul>
        </section>

        <section>
          <h2>Çerez Kullanım Amaçları</h2>
          <ul>
            <li>Online abone sözleşmesi imzalanması, her tür abone işlemlerinin yürütülmesi ile fatura ödeme hizmetlerinin sağlanması</li>
            <li>Web sitesinin trafiği analiz edilmesi</li>
            <li>Ziyaretçilerin web sitesindeki etkileşim biçimini daha iyi anlamak ve zaman içerisinde hatırlamak</li>
            <li>Web sitesi ile etkileşimlerin daha hızlı ve güvenli olması</li>
            <li>Kötü amaçlı etkinlikleri ve/veya ihlalleri tespit etmek</li>
            <li>Web sitesinin performansının arttırılması ve kullanım kolaylığının sağlanması</li>
            <li>Ziyaretçilerin kullanım alışkanlıkları, ihtiyaç duyduğu ve ilgili olduğu hizmet ve/veya ürünlere yönlendirme yapılması</li>
            <li>Ziyaretçilerin ilgili oldukları hizmet ve/veya ürünleri baz alarak reklam yayınlamak ve ihtiyaçlarını hatırlatmak üzere yeniden pazarlama çalışmaları</li>
            <li>Ziyaretçilerin ve Ocrion'un hukuki ve ticari güvenliğini sağlamak</li>
          </ul>
        </section>

        <section>
          <h2>Diğer İnternet Sitelerine Bağlantı</h2>
          <p>
            Web Sitesi, diğer internet sitelerine ait bağlantılar içermektedir. Bu sitelerin yöneticilerinin veri koruma hükümlerine uygun hareket edip etmedikleri hususunda hiçbir etkimiz bulunmamaktadır. Ocrion, link verdiği sitelerin içeriklerinden (ve o sitelerin link verdiği diğer sitelerin içeriklerinden) sorumlu değildir ve bağlantı sağladığı sitelerin içeriklerine müdahale yetkisi bulunmamaktadır.
          </p>
        </section>

        <section>
          <h2>Değişiklikler</h2>
          <p>
            Web Sitesi, teknolojik ve hukuki gelişmelere bağlı olarak değişim ve gelişim göstermekte olduğundan işbu Çerez Politikası da bu doğrultuda zaman zaman güncellenecek olup, kullanıcı tarafından kabul edilen versiyonu saklanacak ve geçerli olacaktır. Çerez Politikası'nın güncel hali ziyaret edilen web adresinde yayınlanarak sizlerin erişimine her daim hazır bulundurulacaktır.
          </p>
        </section>

        <section>
          <h2>Çerezleri Kontrol Etme, Devre Dışı Bırakma veya Silme</h2>
          <ul>
            <li><a href="https://support.google.com/chrome/answer/95647" target="_blank" rel="noopener noreferrer">Google Chrome (Bilgisayar, Android, iPhone ve iPad)</a></li>
            <li><a href="https://support.mozilla.org/tr/kb/web-sitesi-tercihleri-cerezleri-acip-kapatma" target="_blank" rel="noopener noreferrer">Mozilla Firefox</a></li>
            <li><a href="https://support.microsoft.com/tr-tr/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener noreferrer">Internet Explorer</a></li>
            <li><a href="http://www.opera.com/browser/tutorials/security/privacy/" target="_blank" rel="noopener noreferrer">Opera (Bilgisayar)</a></li>
            <li><a href="https://help.opera.com/en/mobile/android/#privacy" target="_blank" rel="noopener noreferrer">Opera (Mobil)</a></li>
            <li><a href="https://support.apple.com/kb/PH5042?locale=en_US" target="_blank" rel="noopener noreferrer">Safari (Bilgisayar)</a></li>
            <li><a href="https://support.apple.com/en-us/HT201265" target="_blank" rel="noopener noreferrer">Safari (Mobil)</a></li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default CookiePolicy; 