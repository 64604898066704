import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { blogService } from '../services/blogService';
import '../styles/Blog.css';
import '../styles/BlogPost.css';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('tr-TR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }).replace(/\//g, '.');
};

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();
  const location = useLocation();

  const formatBlogTitle = (slug) => {
    const words = slug.split('-');
    
    const specialWords = {
      'rgm': 'RGM',
      'tpm': 'TPM',
      'ai': 'AI',
      've': 'Ve',
      'perakende': 'Perakende',
      'aktivitelerinde': 'Aktivitelerinde',
      'ocrion': 'Ocrion',
      'anlik': 'Anlık',
      'veri': 'Veri',
      'aninda': 'Anında',
      'aksiyon': 'Aksiyon',
      'revenue': 'Revenue',
      'growth': 'Growth',
      'management': 'Management'
    };

    return words.map(word => 
      specialWords[word.toLowerCase()] || 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const data = await blogService.getPostBySlug(slug);
        setPost(data);
        document.title = data.title;
      } catch (error) {
        console.error('Error fetching post:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!post) {
    return <div>Post not found</div>;
  }

  return (
    <div className="blog-post-container">
      <article className="blog-post">
        <header className="post-header">
          <h1 className="post-title">{post.title}</h1>
          <div className="post-meta">
            <span className="post-date">{formatDate(post.date)}</span>
            <span className="post-author">by {post.author}</span>
            <span className="post-read-time">{post.readTime} min read</span>
          </div>
        </header>
        <div className="blog-image-header">
          <img src={post.image} alt={post.title} />
        </div>
        <div className="blog-content">
          <div 
            className="post-content"
            dangerouslySetInnerHTML={{ __html: post.content }} 
          />

          <footer className="post-footer">
            <div className="post-tags">
              {post.tags.map((tag, index) => (
                <span key={index} className="tag">#{tag}</span>
              ))}
            </div>
          </footer>
        </div>
      </article>
    </div>
  );
};

export default BlogPost; 