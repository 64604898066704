import React from 'react';
import '../styles/About.css';
import aboutImg from '../assets/slides/about/company-growth.png';

const About = () => {
  return (
    <div className="about-page">
      <div className="container">
        <div className="about-header">
          <h1>
            <span className="company-name">OCRION</span>
            <span className="title-description">Tüketim Ürünleri Sektöründe Yeni Nesil Çözüm</span>
          </h1>
        </div>
        <div className="about-content">
          <div className="about-text">
            <p>
              OCRION, Ticari Promosyon Optimizasyonu (TPO), Ticari Promosyon Yönetimi (TPM) ve Gelir Büyüme Yönetimi (RGM) alanlarında derin uzmanlığa sahip sektör ve teknoloji liderleri tarafından geliştirilmiş, işinizi geleceğe taşıyan stratejik bir çözümdür.
            </p>
            <p>
              Geleneksel perakende aktivite yönetimi çözümleri, şirket verilerini kullanarak operasyonel verimlilik sağlamaya odaklanırken, OCRION bunun çok ötesine geçer. Pazar verilerini parmaklarınızın ucuna getirerek rakip fiyatları ve aktivitelerini de denkleme dahil eder; gelişmiş yapay zeka desteğiyle doğru müşteri ve doğru kanal için en etkili promosyonları önerir. Bu sayede, yalnızca verimliliği artırmakla kalmaz, aynı zamanda ticari başarıyı da maksimize edersiniz.
            </p>
            <p>
              OCRION, geleneksel TPM'in sınırlarını aşarak TPO ve RGM'yi kapsayan kapsamlı bir çözüm sunar. Bulut tabanlı ve esnek yapısıyla düşük uygulama maliyetleri, optimize edilmiş kullanıcı deneyimi ve SAP ERP müşterileri için özel olarak yapılandırılmış içerikle hızlı ve sorunsuz bir entegrasyon imkânı sağlar.
            </p>
            <p>
              Gerçek zamanlı pazar içgörüleri, rakip analizleri ve yapay zeka destekli tahminleme yetenekleriyle, promosyon süreçlerinizi sadece yönetmekle kalmaz, en ideal kurguyu oluşturmanıza da yardımcı olur. Rakipsiz bir çözüm sunan OCRION, ticari promosyonlarınızı sezgisel değil, veri odaklı stratejilerle yönetmenizi sağlayarak sizi her zaman bir adım öne taşır.
            </p>
            <p className="highlight">
              Satış ve pazarlamada fark yaratmak için OCRION ile tanışın!
            </p>
          </div>
          <div className="about-image">
            <img src={aboutImg} alt="Company Growth" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About; 