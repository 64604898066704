import React from 'react';
import '../styles/PrivacyPolicy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <div className="container">
        <h1>Aydınlatma Metni</h1>
        
        <section>
          <h2>6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU ("KVKK") USUL VE ESASLARI UYARINCA KİŞİSEL VERİLERİNİZİN KORUNMASI HAKKINDA AYDINLATMA METNİ</h2>
        </section>

        <section>
          <h2>Tanımlar</h2>
          <p>İşbu aydınlatma metninde geçen;</p>
          <ul>
            <li><strong>Açık rıza:</strong> Belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rızayı,</li>
            <li><strong>Anonim hâle getirme:</strong> Kişisel verilerin, başka verilerle eşleştirilerek dahi hiçbir surette kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hâle getirilmesini,</li>
            <li><strong>İlgili kişi:</strong> Kişisel verisi işlenen gerçek kişiyi,</li>
            <li><strong>Kişisel veri:</strong> Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgiyi,</li>
            <li><strong>Kişisel verilerin işlenmesi:</strong> Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlemi,</li>
            <li><strong>Veri işleyen:</strong> Veri sorumlusunun verdiği yetkiye dayanarak onun adına kişisel verileri işleyen gerçek veya tüzel kişiyi,</li>
            <li><strong>Veri kayıt sistemi:</strong> Kişisel verilerin belirli kriterlere göre yapılandırılarak işlendiği kayıt sistemini,</li>
            <li><strong>Veri sorumlusu:</strong> Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişiyi,</li>
            <li><strong>KVKK:</strong> 7 Nisan 2016 tarihinde Resmi Gazete'de yayınlanarak yürürlüğe giren 6698 sayılı Kişisel Verilerin Korunması Kanunu'nu,</li>
            <li><strong>Şirket:</strong> Ocrion A.Ş. ("Ocrion")'yi,</li>
            <li><strong>Website:</strong> ocrion.com'u ifade eder.</li>
          </ul>
        </section>

        <section>
          <h2>1. Veri Sorumlusunun ve Varsa Temsilcisinin Kimliği</h2>
          <p>
            Ocrion A.Ş. ("Ocrion") veri sorumlusu sıfatıyla, kişisel verilerinizi aşağıda açıklanan amaçlar kapsamında; hukuka ve dürüstlük kurallarına uygun bir şekilde işleyebilecek, kaydedebilecek, saklayabilecek, sınıflandırabilecek, güncelleyebilecek ve mevzuatın izin verdiği hallerde ve/veya işlendikleri amaçla sınırlı olarak üçüncü kişilere açıklayabilecek/aktarabileceğiz.
          </p>
        </section>

        <section>
          <h2>2. Kişisel Verilerin Hangi Amaçla İşleneceği</h2>
          <p>KVKK'nın 4., 5. ve 6. maddeleri uyarınca kişisel verileriniz;</p>
          <ul>
            <li>Hukuka ve dürüstlük kurallarına uygun</li>
            <li>Doğru ve gerektiğinde güncel</li>
            <li>Belirli, açık ve meşru amaçlar için</li>
            <li>İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü</li>
            <li>İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre kadar muhafaza edilme</li>
          </ul>
          <p>kurallarına uygun bir şekilde Ocrion A.Ş.'nin aşağıda yer alan faaliyetleri ile bağlantılı olacak şekilde işlenecektir.</p>

          <h3>Ocrion A.Ş. faaliyetleri;</h3>
          <ul>
            <li>Şirketimiz tarafından yürütülen ticari faaliyet gereklerinin yerine getirilmesi, ve hizmetin ifası ile şirketimiz tarafından sunulan ürün ve hizmetlerden ilgili kişileri faydalanmasını sağlamak,</li>
            <li>Şirketimizin ilgili iş birimleri tarafından gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin yürütülmesi ve raporların yapılması,</li>
            <li>Şirketimizin ticari, operasyonel ve iş stratejilerinin belirlenmesi ve uygulanması,</li>
            <li>Kuruma özel satış ve pazarlama faaliyetlerinin planlanması ve gerçekleştirilmesi,</li>
            <li>Şirketimiz ve/veya tedarikçilerimiz tarafından sunulan ürün ve hizmetler ile şirketimizle iş ilişkisi içerisinde olan üçüncü kişilerin hukuki ve ticari güvenliğinin temini,</li>
            <li>Şirket faaliyetlerimizin, şirket prosedürleri veya ilgili mevzuata uygun olarak yürütülmesinin temini,</li>
            <li>Kurumsal sürdürülebilirlik, kurumsal yönetim, stratejik planlama ve bilgi güvenliği süreçlerinin planlanması,</li>
            <li>Finans, iletişim, pazar araştırması ve satın alma operasyonlarımızın yürütülmesi,</li>
            <li>Şirket içi sistem ve uygulama yönetimi operasyonlarının sürdürülmesi,</li>
            <li>Hukuki süreçlerimizin yönetilmesi, tarafınıza kesintisiz olarak daha iyi ve güvenilir hizmet verilebilmesi</li>
          </ul>
          <p>amaçlarıyla 6698 sayılı Kanun'un 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları dahilinde işlenecektir.</p>
        </section>

        <section>
          <h2>3. İşlenen Kişisel Verilerin Kimlere ve Hangi Amaçla Aktarılabileceği</h2>
          <p>
            KVKK'nın 8. ve 9. maddeleri uyarınca kişisel verileriniz yukarıda sayılan amaçlar dahilinde, Ocrion A.Ş.'nin faaliyetlerinin sürdürülebilmesi için Kişisel Veri işleme şartları ve amaçları çerçevesinde aşağıda tanımı yapılan üçüncü kişilere aktarılabilecektir.
          </p>
          <p>
            Kişisel verileriniz; şirketimiz faaliyetlerini ve iş süreçlerini devam ettirmek için yurtiçi ve/veya yurtdışında bulunan ve şirketimize hizmet sunan ve işbirliği yaptığımız iş ortaklarımıza, tedarikçilerimize, sigorta şirketlerimize, noter, banka ve finans kuruluşlarına, hukuk, vergi vb. benzeri alanlarda destek aldığımız danışmanlık firmalarımıza, şirket yetkililerine, grup şirketi ve hissedarlarımıza, kanunen yetkili kamu kurumlarına ve özel kişilere, şirketimiz adına kişisel veri işleyen yurt içi ve/veya yurt dışında depolama, arşivleme, bilişim teknolojileri desteği (sunucu, hosting, yazılım, bulut bilişim vb.) vs. alanlarında destek aldığımız hizmet sağlayıcılarımıza, 6698 sayılı Kanun'un 8. ve 9. Maddelerinde belirtilen kişisel veri işleme şartları ve yukarıda belirtilen amaçlar çerçevesinde aktarılabilecektir.
          </p>
        </section>

        <section>
          <h2>4. Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi</h2>
          <p>Kişisel verileriniz, Ocrion A.Ş. tarafından aşağıdaki kanallardan toplanmaktadır:</p>
          <ul>
            <li>Şirket ve ürün web sayfaları üzerinden toplanan iletişim veya kişisel bilgi içeren bilgi formları</li>
            <li>Şirket alan adı uzantılı e-posta adresine paylaşılan kişisel bilgiler</li>
            <li>Dijital pazarlama kanalları üzerinden toplanan kişisel veriler</li>
            <li>Şirketin üçüncü taraf pazarlama kanalları üzerinden iletilen kişisel bilgiler</li>
            <li>Elektronik kanallar aracılığı ile şirketle paylaşılan kişisel bilgiler</li>
            <li>XMPP, WhatsApp, Telegram, Signal, LinkedIn, Facebook, Meta, Instagram, Twitter, Google, Google Servisleri, Google Cloud, Microsoft Teams, Gitlab, Discord, Jitsi, Citrix Podio üzerinden paylaşılan kişisel veriler</li>
            <li>Microsoft Azure, AWS Amazon, Digital Ocean, Hetzner, Alibaba Cloud ve/veya şirketin çalışmakta olduğu sunucu sağlayıcıları üzerinden paylaşılan kişisel veriler</li>
            <li>SMS ve/veya MMS üzerinden paylaşılan kişisel veriler</li>
            <li>Şirket ile yapılan telefon görüşmelerinde paylaşılan kişisel veriler</li>
            <li>Şirket sanal, eSIM ve/veya telefon görüşmelerinde paylaşılan kişisel veriler</li>
            <li>Şirketin geliştirmiş olduğu ürün ve servisler üzerinden paylaşılan kişisel veriler</li>
          </ul>
        </section>

        <section>
          <h2>5. İlgili Kişinin Hakları</h2>
          <p>Herkes, veri sorumlusuna başvurarak kendisiyle ilgili;</p>
          <ul>
            <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
            <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
            <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
            <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
            <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
            <li>KVKK 7'nci maddesinde öngörülen şartlar çerçevesinde kişisel verilerin silinmesini veya yok edilmesini isteme,</li>
            <li>Yukarıdaki düzeltme ve silme işlemlerinin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
            <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
            <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme,</li>
          </ul>
          <p>haklarına sahiptir.</p>
        </section>

        <section>
          <h2>Diğer İnternet Sitelerine Bağlantı</h2>
          <p>
            Web Sitesi, diğer internet sitelerine ait bağlantılar içermektedir. Bu sitelerin yöneticilerinin veri koruma hükümlerine uygun hareket edip etmedikleri hususunda hiçbir etkimiz bulunmamaktadır. Ocrion, link verdiği sitelerin içeriklerinden (ve o sitelerin link verdiği diğer sitelerin içeriklerinden) sorumlu değildir ve bağlantı sağladığı sitelerin içeriklerine müdahale yetkisi bulunmamaktadır.
          </p>
        </section>

        <section>
          <h2>İletişim</h2>
          <p>
            Yukarıda belirtilen haklarınız ile ilgili Ocrion A.Ş'ye yazılı olarak başvurmanız halinde, talebinizin niteliğine göre en geç 30 (otuz) gün içerisinde ücretsiz olarak yanıt verilecektir. Başvurunuzu açık, anlaşılır bir şekilde ve kimlik ve adres bilgilerinizi tespit edici belgeleri de ekleyerek, yazılı ve ıslak imzalı olarak elden, postayla ya da noter kanalıyla şirket adresine İçerenköy Mah. Topçu İbrahim Sok. Quick Tower No: 8-10D 34752 Ataşehir/İstanbul ulaştırmanız gerekmektedir.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy; 