import { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../styles/Navbar.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    navigate('/login');
    setIsOpen(false);
  };

  const handleDemoClick = () => {
    navigate('/demo');
    setIsOpen(false);
  };

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-brand">
          <Link to="/">
            <img 
              src="/logos/ocrion-long.png" 
              alt="Ocrion" 
              className="logo-long"
            />
          </Link>
        </div>
        
        <button className="mobile-menu-btn" onClick={() => setIsOpen(!isOpen)}>
          <span className={`hamburger ${isOpen ? 'open' : ''}`}></span>
        </button>

        <div className={`navbar-menu ${isOpen ? 'open' : ''}`}>
          <Link to="/" className={`nav-link ${isActive('/')}`} onClick={() => setIsOpen(false)}>Ana Sayfa</Link>
          <Link to="/features" className={`nav-link ${isActive('/features')}`} onClick={() => setIsOpen(false)}>Çözüm</Link>
          <Link to="/partners" className={`nav-link ${isActive('/partners')}`} onClick={() => setIsOpen(false)}>İş Ortaklarımız</Link>
          <Link to="/blog" className={`nav-link ${isActive('/blog')}`} onClick={() => setIsOpen(false)}>Blog</Link>
          <Link to="/about" className={`nav-link ${isActive('/about')}`} onClick={() => setIsOpen(false)}>Hakkımızda</Link>
          <Link 
            to="/demo" 
            className="demo-button"
            onClick={handleDemoClick}
          >
            Demo Talebi
          </Link>
          <Link to="/contact" className={`nav-link ${isActive('/contact')}`} onClick={() => setIsOpen(false)}>İletişim</Link>
          {isAuthenticated ? (
            <>
              <Link to="/admin" className="nav-link" onClick={() => setIsOpen(false)}>Admin</Link>
              <button onClick={handleLogout} className="nav-link logout-btn">Çıkış</button>
            </>
          ) : (
            <Link to="/login" className="login-btn" onClick={() => setIsOpen(false)}>Giriş</Link>
          )}
          <img 
            src="/logos/ocrion.png" 
            alt="Ocrion Icon" 
            className="logo-icon"
          />
        </div>
      </div>
    </nav>
  );
}

export default Navbar; 
