import React, { useEffect, useState } from 'react';
import '../styles/Features.css';
import realtimeImg from '../assets/slides/features/realtime.jpg';
import aiImg from '../assets/slides/features/ai.jpg';
import integrationImg from '../assets/slides/features/integration.jpg';
import frontendImg from '../assets/slides/features/frontend.jpg';
import experienceImg from '../assets/slides/features/experience.jpg';
import effortImg from '../assets/slides/benefits/effort.jpg';
import timeImg from '../assets/slides/benefits/time.jpg';
import budgetImg from '../assets/slides/benefits/budget.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faBrain, faChartLine, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import salesManagerImg from '../assets/slides/expectations/SatisYoneticileri.jpg';
import salesDirectorImg from '../assets/slides/expectations/SatisDirektoru.jpg';
import commercialMarketingImg from '../assets/slides/expectations/TicariPazarlama.jpg';
import budgetPlanningImg from '../assets/slides/expectations/ButcePlanlama.jpg';
import regionManagersImg from '../assets/slides/expectations/BolgeYoneticileri.jpg';
import demandPlanningImg from '../assets/slides/expectations/TalepPlanlamasi.jpg';
import accountingImg from '../assets/slides/expectations/Muhasebe.jpg';
import fieldSalesImg from '../assets/slides/expectations/SahaSatisTemsilcileri.jpg';
import problem1Img from '../assets/slides/problems/1.jpg';
import problem2Img from '../assets/slides/problems/2.jpg';
import problem3Img from '../assets/slides/problems/3.jpg';
import problem4Img from '../assets/slides/problems/4.jpg';
import problem5Img from '../assets/slides/problems/5.jpg';
import problem6Img from '../assets/slides/problems/6.jpg';

const Features = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setScrollPosition(prev => (prev + 0.5) % 100); // Daha yavaş ve daha az bellek kullanımı
    }, 50);
    
    return () => clearInterval(interval);
  }, []);

  const companies = [
    { name: 'Eczacıbaşı', logo: '/logos/eczacibasi.png' },
    { name: 'ETI', logo: '/logos/eti.png' },
    { name: 'Sütaş', logo: '/logos/sutas.png' },
    { name: 'Upfield', logo: '/logos/upfield.png' },
    { name: 'Kimberly-Clark', logo: '/logos/kimberly.png' },
    { name: 'IATCO', logo: '/logos/iatco.png' },
  ];

  return (
    <div className="features-page">
      <section className="problems-section">
        <div className="container">
          <h2 className="section-title">Perakende Yönetiminde Karşılaşılan Zorluklar</h2>
          <div className="problems-list">
            <div className="problem-item">
              <div className="problem-icon">
                <img src={require('../assets/slides/problems/1.jpg')} alt="Müşteri ve Ürün" />
              </div>
              <p>Doğru müşteri ve ürünlere aktivite planlama ve takibinde zorluk</p>
            </div>

            <div className="problem-item">
              <div className="problem-icon">
                <img src={require('../assets/slides/problems/2.jpg')} alt="Rakip Analiz" />
              </div>
              <p>Rakip firma aktivite ve fiyatlarını analizlerde kullanamama</p>
            </div>

            <div className="problem-item">
              <div className="problem-icon">
                <img src={require('../assets/slides/problems/3.jpg')} alt="Hakediş" />
              </div>
              <p>Hakediş faturalarının doğruluğu ve süresi. Doğru ürüne maliyetlendirme</p>
            </div>

            <div className="problem-item">
              <div className="problem-icon">
                <img src={require('../assets/slides/problems/4.jpg')} alt="Denetim" />
              </div>
              <p>Denetime uygun onay adımların takibinde zorluk</p>
            </div>

            <div className="problem-item">
              <div className="problem-icon">
                <img src={require('../assets/slides/problems/5.jpg')} alt="Veri Ölçüm" />
              </div>
              <p>Farklı kaynaklarda veri olması nedeniyle aktivite etkinliğinin ölçümünde zorluk</p>
            </div>

            <div className="problem-item">
              <div className="problem-icon">
                <img src={require('../assets/slides/problems/6.jpg')} alt="Bütçe" />
              </div>
              <p>Dönem içinde harcama bütçesi yönetiminde zorluk</p>
            </div>
          </div>
        </div>
      </section>

      <section className="features-section expectations">
        <div className="container">
          <h2 className="section-title">Tüketim Ürünlerinde Ekiplerin Stratejik Beklentileri</h2>
          <div className="expectations-grid">
            <div className="expectation-card">
              <div className="expectation-image">
                <img src={salesManagerImg} alt="Satış Yöneticileri" />
              </div>
              <h3 className="highlighted-title">Satış Yöneticileri</h3>
              <p>Etkin senelik plan hazırlama, doğru müşteri ve ürünlerde kısa dönemli aktivite planlama, dönem içi analize daha fazla vakit ayırma</p>
            </div>
            
            <div className="expectation-card">
              <div className="expectation-image">
                <img src={salesDirectorImg} alt="Satış Direktörü" />
              </div>
              <h3 className="highlighted-title">Satış Direktörü</h3>
              <p>Satış hedefine ulaşma</p>
            </div>
            
            <div className="expectation-card">
              <div className="expectation-image">
                <img src={commercialMarketingImg} alt="Ticari Pazarlama" />
              </div>
              <h3 className="highlighted-title">Ticari Pazarlama</h3>
              <p>Harcama bütçesinin doğru kanal, müşteri ve ürünlerde kullanımı</p>
            </div>
            
            <div className="expectation-card">
              <div className="expectation-image">
                <img src={budgetPlanningImg} alt="Bütçe Planlama" />
              </div>
              <h3 className="highlighted-title">Bütçe Planlama</h3>
              <p>Harcama bütçesinde aşım olmaması</p>
            </div>
            
            <div className="expectation-card">
              <div className="expectation-image">
                <img src={regionManagersImg} alt="Bölge Yöneticileri" />
              </div>
              <h3 className="highlighted-title">Bölge Yöneticileri</h3>
              <p>Aktivite girişlerinin kolay ve hızlı olması</p>
            </div>
            
            <div className="expectation-card">
              <div className="expectation-image">
                <img src={demandPlanningImg} alt="Talep Planlaması" />
              </div>
              <h3 className="highlighted-title">Talep Planlaması</h3>
              <p>Aktivitelere göre talep tahmini revizyonu</p>
            </div>
            
            <div className="expectation-card">
              <div className="expectation-image">
                <img src={accountingImg} alt="Muhasebe" />
              </div>
              <h3 className="highlighted-title">Muhasebe</h3>
              <p>Daha az eforla fatura girişi</p>
            </div>
            
            <div className="expectation-card">
              <div className="expectation-image">
                <img src={fieldSalesImg} alt="Saha Satış Temsilcileri" />
              </div>
              <h3 className="highlighted-title">Saha Satış Temsilcileri</h3>
              <p>Aktivite takibi ve raporlama</p>
            </div>
          </div>
        </div>
      </section>

      <section className="process-section">
        <div className="container">
          <h2 className="section-title highlight-title">
            Uçtan Uca Hazır, Akıllı Aktivite Öneri Çözümü
          </h2>
          
          <div className="process-container">
            <div className="process-step step-1" data-number="01">
              <span className="step-icon">📊</span>
              E-Ticaret
            </div>
            <div className="process-step step-2" data-number="02">
              <span className="step-icon">📑</span>
              Market Broşürleri
            </div>
            <div className="process-step step-3" data-number="03">
              <span className="step-icon">🤖</span>
              gen-AI ve OCR
            </div>
            <div className="process-step step-4" data-number="04">
              <span className="step-icon">📈</span>
              Veri Bilimi
            </div>
            <div className="process-step step-5" data-number="05">
              <span className="step-icon">📋</span>
              Aktivite Planlama
            </div>
          </div>
          
          <div className="info-bar">
            <div className="info-box yellow">Fiyat, Aktivite Verilerinin Alınması</div>
            <div className="info-box blue">gen-AI Destekli Eşleşme</div>
            <div className="info-box green">Veri Keşfi</div>
            <div className="info-box dark-blue">Gen-AI Destekli Aktivite Önerileri</div>
          </div>
          
          <div className="process-features">
            <div className="feature-box">
              <FontAwesomeIcon icon={faRobot} size="2x" />
              <p>Gelişmiş robotlar ve yüksek doğruluk oranına sahip OCR yazılımı</p>
            </div>
            <div className="feature-box">
              <FontAwesomeIcon icon={faBrain} size="2x" />
              <p>AI destekli hızlı ve doğru eşleşme algoritmaları</p>
            </div>
            <div className="feature-box">
              <FontAwesomeIcon icon={faChartLine} size="2x" />
              <p>Hazır veri keşfi şablonları. Büyük veride hız ve doğruluk.</p>
            </div>
            <div className="feature-box">
              <img 
                src="/logos/ocrion.png" 
                alt="Ocrion Logo" 
                className="ocrion-icon"
              />
              <p>OCRION Planlama Yazılımı ile aktivite öneri sistemi kullanımı</p>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="section-header">
          <h2 className="section-title">Neden Farklıyız?</h2>
        </div>
        <div className="features-grid">
          <div className="feature-card">
            <div className="feature-image">
              <img src={realtimeImg} alt="Gerçek zamanlı analiz" />
            </div>
            <h2 className="highlighted-title">Gerçek zamanlı analiz</h2>
            <p>Analitik platformun üzerine kurulan Ocrion güçlü altyapısı sayesinde veri analizi ve aktivite planlamayı tek uygulamada sağlıyor</p>
          </div>

          <div className="feature-card">
            <div className="feature-image">
              <img src={aiImg} alt="AI Altyapısı" />
            </div>
            <h2 className="highlighted-title">AI Altyapısı</h2>
            <p>Analitik katmanın bir parçası olan AI motoru sayesinde aktivite ve fiyat önerileri sunuyor</p>
          </div>

          <div className="feature-card">
            <div className="feature-image">
              <img src={integrationImg} alt="Entegrasyon" />
            </div>
            <h2 className="highlighted-title">Entegrasyon</h2>
            <p>SAP, Bayi Yönetim Sistemi, 3rd Parti vb. sistemlerle veri alışverişinde hazır entegrasyon şablonları kullanıyor</p>
          </div>

          <div className="feature-card">
            <div className="feature-image">
              <img src={frontendImg} alt="Önyüz Teknolojisi" />
            </div>
            <h2 className="highlighted-title">Önyüz Teknolojisi</h2>
            <p>En güncel önyüz teknolojisi sayesinde kullanıcı dostu ekranlar sunuyor</p>
          </div>
        </div>
      </div>

      <section className="benefits-section">
        <div className="container">
          <h2>Daha Akıllı Çözümlerle Daha Yüksek Ciro!</h2>
          <div className="benefits-content">
            <div className="benefits-text">
              <p className="main-text">
                <span className="highlight">OCRION</span> ile aktivitelerinizi <span className="highlight">DAHA AZ EFOR</span> ile yürütür, daha fazla <span className="highlight">ZAMAN KAZANIR</span>,
              </p>
              <p className="main-text">
                Aynı sayıda aktiviteyi <span className="highlight">DAHA DÜŞÜK BÜTÇE</span> ile yürütür, <span className="highlight">SATIŞ HEDEFLERİNİZE</span> daha hızlı ulaşır,
              </p>
              <p className="main-text">
                <span className="highlight">DAHA KÂRLI SATIŞ</span> yaparsınız...
              </p>
            </div>
            <div className="benefits-images">
              <div className="benefit-image">
                <img src={effortImg} alt="Düşük Efor" />
              </div>
              <div className="benefit-image">
                <img src={budgetImg} alt="Bütçe Tasarrufu" />
              </div>
              <div className="benefit-image">
                <img src={timeImg} alt="Zaman Tasarrufu" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="trusted-brands">
        <div className="container">
          <h2>Sektör ve Süreç Tecrübesi</h2>
          <div className="logo-banner">
            <div 
              className="logo-scroll" 
              style={{ 
                transform: `translateX(-${scrollPosition}%)`,
              }}
            >
              {companies.map((company, index) => (
                <div key={index} className="logo-item">
                  <img src={company.logo} alt={company.name} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="management">
        <div className="container">
          <h2 className="section-title">En Yeni Teknoloji ile Perakende Aktivitelerinizi Uçtan Uca Yönetin</h2>
          {/* ... */}
        </div>
      </section>
    </div>
  );
};

export default Features; 