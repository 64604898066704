import React from 'react';
import { Link } from 'react-router-dom';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('tr-TR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }).replace(/\//g, '.');
};

const BlogPost = ({ post }) => {
  return (
    <article className="blog-post">
      {post.thumbnail && (
        <div className="post-thumbnail">
          <img src={post.thumbnail} alt={post.title} />
        </div>
      )}
      <div className="post-content">
        <h2 className="post-title">
          <Link to={`/blog/${post.slug}`} className="post-link">
            {post.title}
          </Link>
        </h2>
        <div className="post-meta">
          <span className="post-date">{formatDate(post.date)}</span>
          <span className="post-author">by {post.author}</span>
        </div>
        <div className="post-excerpt" 
          dangerouslySetInnerHTML={{ __html: post.excerpt }} 
        />
        <Link to={`/blog/${post.slug}`} className="read-more">
          Read More
        </Link>
      </div>
    </article>
  );
};

export default BlogPost; 