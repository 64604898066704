import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { blogService } from '../services/blogService';

const TagCloud = () => {
  const [tags, setTags] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTag = searchParams.get('tag');

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const data = await blogService.getTags();
        setTags(data);
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };

    fetchTags();
  }, []);

  const handleTagClick = (tagSlug) => {
    const params = new URLSearchParams(searchParams);
    params.set('tag', tagSlug);
    params.set('page', '1');
    setSearchParams(params);
  };

  return (
    <div className="tag-cloud">
      <h3>Tags</h3>
      <div className="tag-list">
        {tags.map(tag => (
          <button
            key={tag.id}
            onClick={() => handleTagClick(tag.slug)}
            className={`tag ${currentTag === tag.slug ? 'active' : ''}`}
          >
            {tag.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TagCloud; 