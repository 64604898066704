import React from 'react';
import '../styles/Partners.css';

const platformPartners = [
  {
    name: 'AWS',
    logo: '/logos/partners/aws.png',
    description: 'Amazon Web Services'
  },
  {
    name: 'Azure',
    logo: '/logos/partners/azure.png',
    description: 'Microsoft Azure Cloud Services'
  }
];

const solutionPartners = [
  {
    name: 'Voyacon Bilişim Teknolojileri',
    logo: '/logos/partners/voyacon.png',
    description: 'Teknoloji Çözüm Ortağı',
    url: 'https://voyacon.com/'
  },
  {
    name: 'Trendbox',
    logo: '/logos/partners/trendbox.png',
    description: 'İş Geliştirme Çözüm Ortağı',
    url: 'https://trendbox.io/'
  }
];

const integrationPartners = [
  {
    name: 'BTech Analytics',
    logo: '/logos/partners/btech.png',
    description: 'Veri Analizi ve Entegrasyon Ortağı',
    url: 'https://community.btechanalytics.net/'
  },
  {
    name: 'Robomotion',
    logo: '/logos/partners/robomotion.png',
    description: 'RPA ve Otomasyon Ortağı',
    url: 'https://www.robomotion.io/'
  },
  {
    name: 'Opwire App',
    logo: '/logos/partners/opwire.png',
    description: 'Teknik Entegrasyon Ortağı',
    url: 'https://opwire.app/'
  }
];

// Platform Partner kartı (URL'siz)
const PlatformPartnerCard = ({ partner }) => (
  <div className="partner-card">
    <div className="partner-logo">
      <img src={partner.logo} alt={partner.name} />
    </div>
    <h3>{partner.name}</h3>
    <p>{partner.description}</p>
  </div>
);

// URL'li Partner kartı
const LinkedPartnerCard = ({ partner }) => (
  <a 
    href={partner.url} 
    target="_blank" 
    rel="noopener noreferrer" 
    className="partner-card"
  >
    <div className="partner-logo">
      <img src={partner.logo} alt={partner.name} />
    </div>
    <h3>{partner.name}</h3>
    <p>{partner.description}</p>
  </a>
);

const Partners = () => {
  return (
    <div className="partners-page">
      <div className="container">
        <section className="partners-section">
          <h2>Platform İş Ortaklarımız</h2>
          <div className="partners-grid">
            {platformPartners.map((partner, index) => (
              <PlatformPartnerCard key={index} partner={partner} />
            ))}
          </div>
        </section>

        <section className="partners-section">
          <h2>Çözüm Ortaklarımız</h2>
          <div className="partners-grid">
            {solutionPartners.map((partner, index) => (
              <LinkedPartnerCard key={index} partner={partner} />
            ))}
          </div>
        </section>

        <section className="partners-section">
          <h2>Entegrasyon İş Ortaklarımız</h2>
          <div className="partners-grid">
            {integrationPartners.map((partner, index) => (
              <LinkedPartnerCard key={index} partner={partner} />
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Partners; 