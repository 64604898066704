import React, { useState, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from '@emailjs/browser';
import '../styles/Demo.css';

const Demo = () => {
  const form = useRef();
  const recaptchaRef = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!captchaValue) {
      setSubmitStatus({
        type: 'error',
        message: 'Lütfen robot olmadığınızı doğrulayın.'
      });
      return;
    }

    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      await emailjs.sendForm(
        'service_e1rgxqd',
        'template_w1w0fef',
        form.current,
        'Jc0RhqEdIrYTorW0K'
      );

      setSubmitStatus({
        type: 'success',
        message: 'Demo talebiniz başarıyla iletildi. En kısa sürede size dönüş yapacağız.'
      });
      form.current.reset();
      recaptchaRef.current.reset();
      setCaptchaValue(null);
    } catch (error) {
      setSubmitStatus({
        type: 'error',
        message: 'Bir hata oluştu. Lütfen daha sonra tekrar deneyin.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <div className="demo-page">
      <div className="demo-content">
        <div className="demo-left">
          <div className="demo-logo">
            <img src="/logos/ocrion-long.png" alt="Ocrion Logo" />
          </div>
          <h1>
            Tüketim Ürünlerinde
            <span className="highlight">Yeni Nesil Çözümle</span>
            <span className="tanishin">Tanışın</span>
          </h1>
          <div className="features">
            <div className="feature">
              <div className="feature-icon">
                <i className="fas fa-users"></i>
              </div>
              <span>Doğru Müşteri</span>
            </div>
            <div className="feature">
              <div className="feature-icon">
                <i className="fas fa-box"></i>
              </div>
              <span>Doğru SKU</span>
            </div>
            <div className="feature">
              <div className="feature-icon">
                <i className="fas fa-tags"></i>
              </div>
              <span>Doğru Fiyat</span>
            </div>
          </div>
        </div>
        
        <div className="demo-right">
          <form ref={form} onSubmit={handleSubmit} className="demo-form">
            <div className="form-row">
              <input
                type="text"
                name="name"
                placeholder="Ad-Soyad"
                className="form-input"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="E-Posta"
                className="form-input"
                required
              />
            </div>
            <div className="form-row">
              <input
                type="tel"
                name="phone"
                placeholder="Telefon (Örnek: 532 123 12 12)"
                className="form-input"
                required
              />
              <input
                type="text"
                name="company"
                placeholder="Firma"
                className="form-input"
                required
              />
            </div>
            <textarea
              name="message"
              placeholder="Mesajınızı Buraya Yazın..."
              className="form-input"
              rows="4"
              required
            />
            
            <button 
              type="submit" 
              disabled={isSubmitting || !captchaValue}
              className="submit-button"
            >
              {isSubmitting ? 'Gönderiliyor...' : 'Formu Gönder'}
            </button>

            <div className="form-group recaptcha-container">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LdmD9AqAAAAAJjt3wDwQCozEgh-n4P3kc0loSFI"
                onChange={onCaptchaChange}
              />
            </div>

            {submitStatus && (
              <div className={`submit-status ${submitStatus.type}`}>
                {submitStatus.message}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Demo; 