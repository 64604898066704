import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';

const Footer = () => {
  useEffect(() => {
    // LinkedIn script'ini yükle
    const script = document.createElement('script');
    script.src = 'https://platform.linkedin.com/in.js';
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    script.innerHTML = 'lang: en_US';
    
    // Follow button container'ı oluştur
    const container = document.getElementById('linkedin-follow-container');
    if (container) {
      // Önceki içeriği temizle
      container.innerHTML = '';
      
      // Follow button elementini oluştur
      const followButton = document.createElement('div');
      followButton.className = 'linkedin-follow-button';
      followButton.innerHTML = `
        <script type="IN/FollowCompany" 
          data-id="31413470" 
          data-counter="bottom">
        </script>
      `;
      container.appendChild(followButton);
    }

    // Script'i yükle
    document.body.appendChild(script);

    // Script yüklendikten sonra parse et
    script.onload = () => {
      if (window.IN && typeof window.IN.parse === 'function') {
        window.IN.parse();
      }
    };

    return () => {
      // Cleanup
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
      const container = document.getElementById('linkedin-follow-container');
      if (container) {
        container.innerHTML = '';
      }
    };
  }, []);

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <div className="footer-logo">
            <img src="/logos/ocrion-long.png" alt="Ocrion Logo" />
          </div>
          <p className="company-description">
            <span>Tüketim Ürünleri Sektöründe</span>
            <span>Yeni Nesil Çözüm</span>
          </p>
          <div className="social-links">
            <a 
              href="https://www.linkedin.com/company/ocrion" 
              target="_blank" 
              rel="noopener noreferrer"
              className="social-link linkedin"
              aria-label="LinkedIn'de Takip Et"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a 
              href="https://x.com/OcrionRGM" 
              target="_blank" 
              rel="noopener noreferrer"
              className="social-link twitter"
              aria-label="X'de (Twitter) Takip Et"
            >
              <i className="fa-brands fa-x"></i>
            </a>
            {/* LinkedIn Follow Button */}
            <div id="linkedin-follow-container" className="linkedin-follow"></div>
          </div>
        </div>

        <div className="footer-section">
          <h3>Menü</h3>
          <ul>
            <li><Link to="/">Ana Sayfa</Link></li>
            <li><Link to="/features">Çözüm</Link></li>
            <li><Link to="/partners">İş Ortaklarımız</Link></li>
            <li><Link to="/blog">Blog</Link></li>
            <li><Link to="/about">Hakkımızda</Link></li>
          </ul>
        </div>

        <div className="footer-section">
          <h3>İletişim</h3>
          <ul>
            <li><Link to="/demo">Demo Talebi</Link></li>
            <li><Link to="/contact">İletişim</Link></li>
            <li><Link to="/login">Giriş</Link></li>
          </ul>
        </div>

        <div className="footer-section">
          <h3>Yasal</h3>
          <ul>
            <li><Link to="/cookie-policy">Çerez Politikası</Link></li>
            <li><Link to="/privacy-policy">Gizlilik Politikası</Link></li>
          </ul>
        </div>
      </div>
      
      <div className="footer-bottom">
        <p>© {new Date().getFullYear()} Ocrion. Tüm hakları saklıdır.</p>
      </div>
    </footer>
  );
};

export default Footer; 